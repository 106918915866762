section.tableBox{
    .card {
        border-radius: 8px;

        .card-header{
            margin-left: 2px;
            vertical-align: middle;
            color: $primary-orange;
            .card-title{
                font-weight: 600;
            }
            i{
                font-size: 1.571rem;
                margin-right: .5rem !important;
            }

            .card-tools{
                margin-right: 10px !important;
            }
            .card-select-box{
                color: $grey-500;
            }
        }

        .card-body{
            padding: 0;
        }
    }
    table {
        thead th{
            border-bottom: 1px solid $grey-100;
        }
        font-size: 1rem;
        th{
            white-space: nowrap;
            span{
                padding-right: 50px;
            }
        }

        td, th{
            vertical-align: middle !important;
        }
        //Table Icons
        td button.btn i {
            color: $primary-orange;
            font-size: 1.05rem;
            font-weight: 400 !important;
        }

        tr{

            &.red-border td:first-child { border-left: 6px solid $action-red; }
            &.yellow-border td:first-child { border-left: 6px solid $action-yellow; }
            &.green-border td:first-child { border-left: 6px solid $action-green; }
              
            th{
                padding: 1.5rem .75rem;
            }
            td{
                padding: .75rem;
                border: 0;

            }
            th,td{
                div{
                    border-right: 1px solid $grey-100;
                }
            }
            th:first-child,
            td:first-child{
                padding-left: 1rem;
            }
            th:last-child,
            td:last-child{
                padding-right: 1rem;
            }

            div.statistic{
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: unset;
            }
            span.statisticCircle{
                width: .75em;
                height: .75em;
                border-radius: 50%;
                margin: 0 5px;

                &.red{
                    background-color: $action-red;
                }
                &.green{
                    background-color: $action-green;
                }
                &.yellow{
                    background-color: $action-yellow;
                }
            }

            div.dateiIconWrapper{
                display: flex;
                gap: 5px;
                align-items: center;
            }

            &.expanded-row{
                background-color: $grey-300 !important;

                >td{
                    padding: 5px 5px 5px 5px;
                }
            }
        }
        
        &.table-striped tbody tr{
            &:nth-of-type(odd){
                background-color: $grey-50;
            }
            &:nth-of-type(even){
                background-color: #fff;
            }
    
            &:hover{
                background-color: $grey-100;
            }
        }

        //embedded table
        tr:has(+ tr.expanded-row){
            background-color: $grey-300 !important;
            color: #fff !important;
            font-weight: 600;
            td{
                border: 0;
            }
        }
        
        td table{
            td{
                border-bottom: 0;
            }
        }


        .expanded-row{
            table{
                margin-bottom: 0;
                border-collapse: separate;
                border-spacing: 0px;
            }
        }

    }

    .tableAction{
        .dropdown-menu{
            padding: 0;
            button{
                display: flex;
                align-items: center;
                padding: 8px 12px 8px 12px;
             
                svg{
                    color: $primary-orange;
                    margin-right: 12px;
                }
            }

            .dropdown-divider {
                margin: 0;
            }

            button.deleteItem,
            button.deleteItem svg{
                color: $action-red;
            }

        }
    }
       

}