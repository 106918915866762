//Header navbar
ul.navbar-nav{
    width: 100%;
    // max-height: 92px;
    .nav-item-right{
        margin-left: auto;
    }

    //DashboardMenu Link Icon
    div.link-wrapper{
        display: flex;
        align-items: center;
        height: 100%;

        a.nav-link{
            z-index: 1040;
            margin-left: -25px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 32px;
            width: 32px;
            border: 1px solid #F1F0F0;
            background: #FFF;
            border-radius: 100%;
            padding: 0;


        }
    }

    //Icon Text Combo
    div.nav-icon-txt{
        position: relative;
        &:hover{
            background: $grey-50;
        }
        >div:first-of-type{
            display: flex; 
            margin: 18px;
            margin-left: 32px;
            padding: 8px;
            padding-right: 31px;
            &:hover{
                cursor: pointer;
            }

            .img-wrapper{
                img{
                    width: 40px;
                    height: 40px;
                    object-fit: scale-down;
                    background-color: #444140;
                }
                .icon-wrapper{
                    width: 40px;
                    height: 40px;
                    background-color: #444140;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i{
                        font-size: 1.429rem;
                        color: white;
                    }
                }
            }
    
            .info{
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 12px;
                min-width: 100px;
                p{
                    margin: 0;
                    &.txt-main{
                        color: #444140;
                        font-size: 0.857rem;
                        text-transform: uppercase;
                        font-weight: 700;
                    }
                    &.txt-under{
                        color: #8C8784;
                        font-size: 0.857rem;
                        font-weight: 500;
                    }
                }
            }
    
            .dropDownAnkle{
                margin-left: 12px;
                position: relative;
                svg{
                    position: absolute;
                    top: 2px;
                }
            }
        }

        >div.firmenSelect{
            &[aria-disabled="true"] {
                pointer-events: none;
                opacity: 0.75;
              }
        }

        >div.profileSelect{
            margin: 18px 42px 18px 18px;
        }


        .dropdownArea{
            position: absolute;
            width: 100%;
            background-color: #fff;
            margin-top: -10px;
            ul.navbar-nav{
                height: 100% ;
                font-size: 1em !important;
                flex-direction: column; 
                border-radius: 4px;
                border-top: 2px solid $primary-orange-light;
                box-shadow: $box-shadow-01;           
                li{
                    color: $grey-500;
                    padding: 8px 16px;
                    align-items: center;
                    // border: 1px solid $grey-100;
                    // box-shadow: $box-shadow-01;

                    &:hover{
                        background: $grey-50;
                        color: $grey-300;
                        cursor: pointer;
                    }
                    &.active{
                        background: $grey-50;
                        cursor: not-allowed;
                        font-weight: 600;
                    }
                }
            }
        }
        
    }



}